<script>
  export let movie;
  export let backdropImageUrl;
  export let movieTrailerUrl;
  export let isOpen = false;

  import { afterUpdate, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import Availability from "$lib/Availability.svelte";
  import { createEventDispatcher } from "svelte";
  import MoviePlayer from "$lib/MoviePlayer.svelte";
  import { recommended_titles, recommended_titles_index } from "./store";

  let showContent = false;

  $: if (isOpen && movie != null) {
    showContent = true;
  }

  let showSkipLeftButon;
  let showSkipRightButton;

  $: if ($recommended_titles_index > 0) {
    showSkipLeftButon = true;
  } else {
    showSkipLeftButon = false;
  }
  $: if ($recommended_titles_index < $recommended_titles.length - 1) {
    showSkipRightButton = true;
  } else {
    showSkipRightButton = false;
  }

  function skipToNextMovie() {
    if ($recommended_titles_index < $recommended_titles.length - 1) {
      recommended_titles_index.set($recommended_titles_index + 1);
      isOpen = false;
      dispatch("close");
    }
  }

  function skipToPreviousMovie() {
    if ($recommended_titles_index > 0) {
      recommended_titles_index.set($recommended_titles_index - 1);
      isOpen = false;
      dispatch("close");
    } else {
      recommended_titles_index.set(0);
    }
  }

  const dispatch = createEventDispatcher();

  function closeModal() {
    recommended_titles_index.set(-1);
    isOpen = false;
    dispatch("close");
  }

  afterUpdate(() => {
    detectElipsis();
    if (window.innerWidth <= 480) {
      var hidingLayerComponent = document.getElementById("hidingComponent");
      if (hidingLayerComponent) {
        hidingLayerComponent.style.background = "rgba(0, 0, 0, 1.8)";
      }
    }
  });

  onMount(() => {
    window.addEventListener("resize", detectElipsis);
    return () => {
      window.removeEventListener("resize", detectElipsis);
    };
  });

  let text_is_ellipsed = false;
  function detectElipsis() {
    if (!showContent || !movie) {
      return;
    } else {
      var synopsis = movie.synopsis;

      if (synopsis.scrollHeight > synopsis.clientHeight || is_expanded) {
        text_is_ellipsed = true;
      } else {
        text_is_ellipsed = false;
      }
    }
  }

  let is_expanded = false;
  function toggleSynopsis() {
    is_expanded = !is_expanded;
  }

  async function copyText(textToCopy) {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Failed to copy the mowizz id to clipboard: ", err);
    }
  }
</script>

<!-- ---------------------------------------------------------------------- -->

{#if isOpen}
  <div class="hiding-layer" id="hidingComponent" on:click={closeModal} role="none">
    <div class="modal-content" role="none" on:click|stopPropagation>
      <div>
        <button class="close-button" on:click={closeModal} />
      </div>
      {#if showContent && movie}
        <MoviePlayer {backdropImageUrl} {movieTrailerUrl} {movie} {isOpen} />
        <div class="modal-body">
          <div class="movie-header">{movie.name}</div>
          {#if movie.name != movie.original_name}
            <div class="movie-header-orig">
              <span class="info-label">{$_("original_title")}:</span>
              {movie.original_name}
            </div>
          {/if}
          <div class="detail-movie">
            <div>
              {#if movie.release_year}
                <span class="info">{movie.release_year}</span>
              {/if}
              {#if movie.production_countries.length > 0}
                <span class="info">
                  {movie.production_countries.join(", ")}
                </span>
              {/if}
              {#if movie.genres.length > 0}
                <span class="info">{movie.genres.join(", ")}</span>
              {/if}
              {#if movie.duration}
                <span class="info">{movie.duration} min</span>
              {/if}
            </div>
            <div>
              {#if movie.directors.length > 0}
                <span class="info-label">{$_("by-director")}:</span>
                <span class="info">{movie.directors.join(", ")}</span>
              {/if}
              {#if movie.cast.length > 0}
                <span class="info-label">{$_("with-cast")}:</span>
                <span class="info">
                  {movie.cast.slice(0, 5).join(", ")}
                </span>
              {/if}
            </div>
          </div>

          <div>
            <p class="synopsis {is_expanded ? 'expanded' : ''}" id="synopsis" title={movie.synopsis}>
              {movie.synopsis}
            </p>
            <button
              id="expandButton"
              on:click={toggleSynopsis}
              style="visibility: {text_is_ellipsed ? 'visible' : 'hidden'}"
              class="synopsis-button {is_expanded ? 'collapse' : 'expand'}" />
          </div>

          <div class="streaming-links">
            {#if movie.streaming_availabilities.filter( (a) => ["RENT", "BUY", "FREE"].includes(a.monetization_type) ).length > 0}
              <div class="type-segment">
                <div class="streamers-segments-header">
                  {$_("streamer-category-rent")} / {$_("streamer-category-buy")}
                </div>
                {#each movie.streaming_availabilities.filter( (a) => ["RENT", "BUY", "FREE"].includes(a.monetization_type) ) as availability}
                  <Availability {availability} {movie} />
                {/each}
              </div>
            {/if}
            {#if movie.streaming_availabilities.filter((a) => a.monetization_type === "FLATRATE").length > 0}
              <div class="type-segment">
                <div class="streamers-segments-header">
                  {$_("streamer-category-subscription")}
                </div>
                {#each movie.streaming_availabilities.filter((a) => a.monetization_type === "FLATRATE") as availability}
                  <Availability {availability} {movie} />
                {/each}
              </div>
            {/if}
          </div>
          <div class="technical-info">
            <button title={$_("copy_to_clipboard")} on:click={() => copyText(movie.id)} />
            <span>mowizz ID: {movie.id}</span>
          </div>
          {#if showSkipLeftButon}
            <button class="skip-left" on:click={skipToPreviousMovie} />
          {/if}
          {#if showSkipRightButton}
            <button class="skip-right" on:click={skipToNextMovie} />
          {/if}
        </div>
      {/if}
    </div>
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .hiding-layer {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--true-black-80);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2%;
    z-index: 1000;
  }
  .modal-content {
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 70%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: var(--true-black);
    line-height: 1.4;
    transition: width 0.3s;
  }
  .close-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1010;
  }
  .close-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
  .modal-body {
    margin-top: -6rem;
    margin-left: 60px;
    margin-right: 60px;
    padding-bottom: 10px;
    position: relative;
    margin-left: 60px;
    margin-right: 60px;
  }
  .movie-header {
    color: var(--white);
    font-size: 40px;
    font-weight: 300;
    line-height: 120%;
  }
  .movie-header-orig {
    color: var(--light-grey);
  }

  .detail-movie {
    color: var(--light-grey);
    margin-top: 1em;
  }

  .info:not(:last-child)::after {
    content: "•";
    white-space: pre;
    margin: 0 0.2em 0 0.5em;
  }
  .info-label {
    margin-right: 0.3em;
  }

  .synopsis {
    color: var(--white-grey);
    margin-top: 20px;
    margin-bottom: 0px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 300;
  }
  .synopsis.expanded {
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
  }
  .synopsis-button {
    width: 24px;
    height: 24px;
    border: none;
    cursor: pointer;
    margin-left: 50%;
  }
  .synopsis-button.expand {
    background: url(/buttons/btn_down.svg) no-repeat;
    background-size: contain;
  }
  .synopsis-button.expand:hover {
    background: url(/buttons/btn_down_hover.svg) no-repeat;
    background-size: contain;
  }
  .synopsis-button.collapse {
    background: url(/buttons/btn_up.svg) no-repeat;
    background-size: contain;
  }
  .synopsis-button.collapse:hover {
    background: url(/buttons/btn_up_hover.svg) no-repeat;
    background-size: contain;
  }
  .skip-left {
    background-image: url("/buttons/btn_left.svg");
    background-color: transparent;
    position: absolute;
    margin-left: -60px;
    top: 20px;
    left: -8px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border: none;
    opacity: 70%;
  }
  .skip-left:hover {
    background-image: url("/buttons/btn_left_hover.svg");
    opacity: 100%;
  }
  .skip-right {
    background-image: url("/buttons/btn_right.svg");
    background-color: transparent;
    position: absolute;
    margin-right: -60px;
    top: 20px;
    right: -8px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border: none;
    opacity: 70%;
  }
  .skip-right:hover {
    background-image: url("/buttons/btn_right_hover.svg");
    opacity: 100%;
  }

  @media (max-width: 1000px) {
    .modal-content {
      width: 88%;
    }
    .modal-body {
      margin-left: 40px;
      margin-right: 40px;
    }
    .skip-left,
    .skip-right {
      top: 20px;
      margin-left: -40px;
      margin-right: -40px;
    }
  }
  @media (max-width: 640px) {
    .modal-content {
      width: 100%;
    }
    .movie-header {
      font-size: 28px;
    }
    .modal-body {
      margin-top: -3rem;
      margin-left: 24px;
      margin-right: 24px;
    }
    .skip-left,
    .skip-right {
      top: 20px;
      margin-left: -28px;
      margin-right: -28px;
    }
  }

  /* ---------streaming links:--------------------- */
  .streaming-links {
    color: var(--white);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .type-segment {
    background-color: var(--dark-grey);
    block-size: -webkit-fit-content;
    block-size: -moz-fit-content;
    block-size: fit-content;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin: 4px;
    padding: 8px 8px 8px;
    position: relative;
  }
  .streamers-segments-header {
    color: var(--light-blue);
    font-size: smaller;
    font-weight: 400;
    margin-bottom: 4px;
    margin-left: 10px;
  }
  .technical-info {
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .technical-info span {
    margin-left: 1em;
    color: var(--white-00);
  }
  .technical-info button {
    background: url(/buttons/btn_copy.svg) no-repeat;
    width: 20px;
    height: 20px;
    border: none;
    position: relative;
    margin-left: 1em;
    cursor: pointer;
  }
</style>
