<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { settings_store } from "$lib/store.js";
  // import { PUBLIC_adsquare_ID } from "$env/static/public";

  // export let active = false;
  let allowAdsense = false;
  let adblockDetected = false;

  function initadsense(document) {
    if ($settings_store.consent_ad_cookies) {
      allowAdsense = true;

      let script = document.createElement("script");
      script.async = true;
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2331971626361986";
      script.crossorigin = "anonymous";

      document.body.appendChild(script);
    }
  }

  function trackDonationBanner() {
    window._paq.push(["trackEvent", "donation-banner", "clicked"]);
  }

  onMount(() => {
    initadsense(window.document);

    setTimeout(() => {
      const adContainer = document.querySelector(".adsquare");
      // Check if the ad container has any child elements (ads) or remains empty
      if (!adContainer || adContainer.innerHTML.trim() === "") {
        adblockDetected = true; // No content, possibly blocked
      }
    }, 3000); // Wait 3 seconds to give the ad time to load
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="ad banner1">
  <div class="adsquare" id="adsquare1">
    {#if allowAdsense && !adblockDetected}
      <!-- Square responsive -->
      <ins
        class="adsbygoogle"
        data-ad-client="ca-pub-2331971626361986"
        data-ad-slot="7426871018"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    {:else if !allowAdsense || adblockDetected}
      <div class="donationrequest">
        <h2>{$_("donation_head")}</h2>
        <p>{$_("donation_text")}</p>
        <a
          href="https://www.paypal.com/donate/?business=VCQTYXPW42KUL&no_recurring=0&item_name={$_(
            'paypal_button_text'
          )}&currency_code=CHF"
          rel="noopener noreferrer"
          on:click={() => trackDonationBanner()}
          target="_blank">
          <img class="donate-btn" alt="DONATE" />
        </a>
      </div>
    {/if}
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .ad {
    grid-column: 1 / 3;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .adsquare {
    color: var(--mowizz-logo-blue);
    background-color: var(--dark-grey);
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    aspect-ratio: 1/1;
    object-fit: scale-down;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    align-content: center;
  }
  .banner1 {
    grid-row: 3;
  }
  /* .banner2 {
    grid-column: -3 / -1;
    grid-row: 8;
  }
  .banner3 {
    grid-row: 13;
  } */
  .adsbygoogle {
    display: block;
  }
  .donate-btn {
    content: url("/buttons/btn_donate.png");
    width: 240px;
    max-width: 100%;
    display: block;
    margin: auto;
  }
  .donate-btn:hover {
    content: url("/buttons/btn_donate_hover.png");
    cursor: pointer;
  }
  h2 {
    font-weight: 600;
  }
  .donationrequest {
    display: block;
    text-align: center;
    margin: auto;
    width: 98%;
    max-width: 300px;
  }
  @media (max-width: 878px) {
    .ad {
      place-items: center;
    }
    .adsquare {
      width: 100%;
      position: relative;
    }
    /* .banner2 {
      grid-row: 15;
    }
    .banner3 {
      grid-row: 27;
    } */
  }
  @media (max-width: 611px) {
    .ad {
      grid-column: 1;
      place-items: center;
    }
    .adsquare {
      width: 100%;
      position: relative;
    }
    /* .banner2 {
      grid-column: 1;
      grid-row: 20;
    }
    .banner3 {
      grid-row: 40;
    } */
  }
  @media (max-width: 430px) {
    .donationrequest p {
      display: none;
    }
  }
</style>
