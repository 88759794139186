<script>
  import { _ } from "svelte-i18n";
  import { startup_state, keywords_store, loading_keywords } from "$lib/store.js";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  // import { reduced } from "$lib/Search.Svelte";

  let keywordsArray = Array.from({ length: 22 }, (value, i) => ({
    category: "mood",
    displayText: $_(`mood${i + 1}`),
    id: `mood${i + 1}`,
    //  dropdownOpen: false,
    incl_excl: "",
    type: "AD_HOC"
  }));
  // $: keywords = $keywords_store;
  // let searchDiv;
  let searchComponent;
  let keywordsDiv;
  let searchFieldContainer;
  let spacer;
  let dropdownOpen = false;
  let activeDropdown = null;

  // Reactive store to store the classes for each inspiration-keyword, so the keyword colors get updated, whenever its instance in keyword_store changes
  let keywordClasses = writable({});

  $: {
    if (keywordsArray && $keywords_store) {
      keywordsArray.forEach((keyword) => keywordState(keyword));
    }
  }

  $: {
    if (!$startup_state) {
      // keywordInspirations.style.height = `${keywordInspirations.scrollHeight - claim.scrollHeight}px`;
      keywordInspirations.style.height = `auto`;
      // searchElements.style.height = `auto`;
    }
  }

  // $: {
  //   if ($search_reduced) {
  //     console.log("reduced");
  //     collapseMoods();
  //   }
  // }

  function deleteKeyword(keyword) {
    keywords_store.update((keywords_old) => {
      keyword.incl_excl = "";
      return keywords_old.filter((t) => t.displayText !== keyword.displayText);
    });
  }

  function changeStartupState() {
    if ($startup_state) {
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight - claim.scrollHeight}px`;
      keywords_store.set([]);
      startup_state.update(() => {
        return false;
      });
    }
  }

  function makeKeywords(keyword) {
    changeStartupState();
    $loading_keywords = true;
    const new_keyword = [
      {
        category: "mood",
        displayText: keyword.displayText,
        id: keyword.id,
        incl_excl: keyword.incl_excl,
        type: "AD_HOC"
      }
    ];
    updateKeywords(new_keyword);
    window._paq.push(["trackEvent", "mood-key", "added", `${new_keyword.displayText}; keyword-id: ${new_keyword.id};`]);
    $loading_keywords = false;
  }

  function updateKeywords(new_keywords) {
    keywords_store.update((keywords) => {
      const combinedKeywords = [...keywords, ...new_keywords];
      const keywordsMap = new Map(combinedKeywords.map((keyword) => [keyword.displayText, keyword]));
      return Array.from(keywordsMap.values());
    });
  }

  let isMoodsExpanded = false;
  let keywordInspirations;
  let moods;
  let searchElements;
  let claim;

  const expandMoods = () => {
    moods.style.height = `${moods.scrollHeight + 44}px`;
    moods.style.paddingBottom = "22px";
    moods.style.paddingTop = "22px";
    const newKeywordInspirationsHeight = moods.scrollHeight + 38 + claim.scrollHeight + spacer.scrollHeight + 44;
    const searchElementsHeight = searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight;
    keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;
    searchElements.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;
    searchComponent.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;

    isMoodsExpanded = true;
    setTimeout(() => {
      moods.style.height = `auto`;
      searchElements.style.height = `auto`;
      claim.style.height = `auto`;
    }, 250);
  };

  const collapseMoods = () => {
    moods.style.paddingBottom = "0px";
    moods.style.paddingTop = "0px";
    moods.style.height = "0";
    window.scrollTo({ top: 0 });
    const newKeywordInspirationsHeight = 38 + claim.scrollHeight + spacer.scrollHeight - 44;
    keywordInspirations.style.height = `${newKeywordInspirationsHeight}px`;

    // const searchElementsHeight = searchFieldContainer.scrollHeight + keywordsDiv.scrollHeight;
    // searchElements.style.height = `${newKeywordInspirationsHeight + searchElementsHeight}px`;
    searchComponent.style.height = searchElements.style.height;

    isMoodsExpanded = false;
  };

  const toggleMoods = () => {
    claim.style.height = `${claim.scrollHeight}px`;
    if (isMoodsExpanded) {
      collapseMoods();
      window._paq.push(["trackEvent", "keyword-inspirations", "closed"]);
      setTimeout(() => {
        searchElements.style.height = `auto`;
        keywordInspirations.style.height = `auto`;
        claim.style.height = `auto`;
      }, 250);
    } else {
      expandMoods();
      window._paq.push(["trackEvent", "keyword-inspirations", "opened"]);
    }
  };

  const adjustMoodsHeight = () => {
    if (keywordInspirations.style.height != "0px") {
      searchElements.style.height = `${searchElements.scrollHeight}px`;
      if (moods.style.height > 0) {
        moods.style.height = `auto`;
        keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
      }
      keywordInspirations.style.height = `auto`;
      searchElements.style.height = `auto`;
    }
  };

  function keywordState(keyword) {
    const matchingID = $keywords_store.find((item) => item.id === keyword.id);
    if (matchingID) {
      if (matchingID.incl_excl === "INCLUDE") {
        // Update keywordClasses store reactively
        keywordClasses.update((classes) => ({ ...classes, [keyword.id]: "included" }));
        return true;
      } else if (matchingID.incl_excl === "EXCLUDE") {
        keywordClasses.update((classes) => ({ ...classes, [keyword.id]: "excluded" }));
        return true;
      }
    } else {
      keywordClasses.update((classes) => ({ ...classes, [keyword.id]: "" }));
      return false;
    }
  }

  function dropdownItemState(keyword, state) {
    if (state == $keywordClasses[keyword.id]) {
      return state;
    }
  }

  function toggleDropdown(keyword) {
    // Close all other dropdowns before toggling the current one
    keywordsArray = keywordsArray.map((d) => {
      if (d !== keyword) {
        return { ...d, dropdownOpen: false }; // Close other dropdowns
      }
      return d;
    });
    keyword.dropdownOpen = !keyword.dropdownOpen;

    if (keyword.dropdownOpen) {
      activeDropdown = keyword;
      // Add document click listener when dropdown opens to close dropdown on click outside
      setTimeout(() => {
        document.addEventListener("pointerdown", closeDropdown);
        document.addEventListener("scroll", closeDropdown);
      }, 0);
    } else {
      document.removeEventListener("pointerdown", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    }
    keywordsArray = [...keywordsArray];
  }

  function closeDropdown() {
    if (activeDropdown === null) {
      return;
    } else {
      activeDropdown.dropdownOpen = false;
      keywordsArray = [...keywordsArray];
      activeDropdown = null;
      document.removeEventListener("pointerdown", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    }
  }

  function getDropdownPosition(keywordID) {
    const keywordElement = document.getElementById(keywordID);
    // commented out code for placing dropdown always inside browser width is not working because the reactive element cannot be found
    // const dropdown = document.querySelector(".dropdown");
    if (keywordElement) {
      // const rect = dropdown.getBoundingClientRect();
      const parentRect = keywordElement.getBoundingClientRect();
      // const parentRect = keywordElement.parentNode.getBoundingClientRect();
      const left = parentRect.left;
      // const right = rect.right;
      const top = parentRect.bottom;
      // if (right > window.innerWidth - 10) {
      //   return `right: ${window.innerWidthh - 10}px; top: ${top}px;`;
      // } else {
      return `left: ${left}px; top: ${top}px;`;
      // }
    }
  }

  onMount(() => {
    // searchDiv = document.querySelector("#search");
    searchComponent = document.querySelector("#search-component");
    searchElements = document.querySelector("#search-elements");
    keywordInspirations = document.querySelector("#keyword-inspirations");
    moods = document.querySelector("#moods");
    claim = document.querySelector("#claim");
    searchFieldContainer = document.querySelector("#search-field-container");
    keywordsDiv = document.querySelector("#keywords");
    spacer = document.querySelector("#spacer");
    keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    keywordInspirations.style.top = `0px`;
    claim.style.height = `auto`;

    // window.addEventListener("scroll", updatekeywordInspirationsProperties);
    window.addEventListener("resize", adjustMoodsHeight);

    return () => {
      // window.removeEventListener("scroll", updatekeywordInspirationsProperties);
      window.removeEventListener("resize", adjustMoodsHeight);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keyword-inspirations">
  <div id="moods">
    {#each keywordsArray as keyword}
      <div class="keyword-unit" id={keyword.id}>
        <div
          class="inspiration-keyword {$keywordClasses[keyword.id] || ''}"
          role="button"
          tabindex="-1"
          aria-expanded={dropdownOpen}
          aria-haspopup="true"
          on:pointerdown={(event) => {
            event.stopPropagation();
          }}
          on:pointerdown={() => toggleDropdown(keyword)}
          on:keypress={() => toggleDropdown(keyword)}>
          <!-- {$_(keyword) ?? keyword} -->
          {keyword.displayText}
        </div>
        {#if keyword.dropdownOpen}
          <div class="dropdown" style={getDropdownPosition(keyword.id)}>
            <div
              class="dropdown-item {dropdownItemState(keyword, 'included')}"
              role="button"
              tabindex="0"
              on:pointerdown={(event) => {
                event.stopPropagation();
              }}
              on:pointerup={(event) => {
                keyword.incl_excl = "INCLUDE";
                closeDropdown();
                makeKeywords(keyword);
                keywordState(keyword);
                event.stopPropagation();
              }}
              on:keydown={() => {
                closeDropdown();
              }}>
              {$keywordClasses[keyword.id] == "included" ? $_("included") : $_("include")}
            </div>
            <div
              class="dropdown-item {dropdownItemState(keyword, 'excluded')}"
              role="button"
              tabindex="0"
              on:pointerdown={(event) => {
                event.stopPropagation();
              }}
              on:pointerup={(event) => {
                keyword.incl_excl = "EXCLUDE";
                closeDropdown();
                makeKeywords(keyword);
                keywordState(keyword);
                event.stopPropagation();
              }}
              on:keydown={() => {
                closeDropdown();
              }}>
              {$keywordClasses[keyword.id] == "excluded" ? $_("excluded") : $_("exclude")}
            </div>
          </div>
        {/if}
        {#if $keywordClasses[keyword.id]}
          <span
            class="delete-keyword"
            role="button"
            tabindex="0"
            on:click={() => deleteKeyword(keyword)}
            on:keypress={() => deleteKeyword(keyword)} />
        {/if}
      </div>
    {/each}
  </div>
  <button id="mood-button" class="inspiration-keyword-button" on:click={toggleMoods}>
    {isMoodsExpanded ? $_("hide_mood_inspirations") : $_("show_mood_inspirations")}
  </button>
  {#if $startup_state}
    <!-- <div id="claim" style="height:{$startup_state ? 'auto' : '0'}"> -->
    <div id="claim">
      <h1>{$_("claim")}</h1>
    </div>
  {/if}
  <div id="spacer" />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #keyword-inspirations {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    margin-top: -1px;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 0px;
    /* padding-bottom: 18px; */
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
  }
  #moods {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    overflow: hidden;
    height: 0;
    background: var(--white-10);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    transition: all 0.25s ease-in-out;
    border-radius: 0 0 24px 24px;
    box-sizing: border-box;
  }
  .inspiration-keyword-button {
    cursor: pointer;
    display: block;
    cursor: pointer;
    color: var(--white-50);
    padding-left: 30px;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0 0 12px 12px;
    font-size: 16px;
    height: 38px;
    text-align: center;
    word-wrap: break-word;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid transparent;
    background: var(--white-10);
    background-image: url("/icons/icon_mood.svg");
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 16px 15px;
  }
  #claim {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    /* padding-bottom: 18px; */
    box-sizing: border-box;
  }
  h1 {
    font-weight: 600;
    color: var(--brightest-red);
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: uppercase;
    box-sizing: border-box;
  }
  #spacer {
    padding-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  .keyword-unit {
    display: flex;
    /* gap: 3px; */
    /* margin: 4px; */
    position: relative;

    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
  }
  .inspiration-keyword {
    display: block;
    cursor: pointer;
    color: var(--solid-grey);
    padding: 0.5rem 1rem;
    border-radius: 12px;
    font-size: 16px;
    height: 20px;
    margin: 2px 3px;
    text-align: center;
    word-wrap: break-word;
    max-width: 9rem;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid transparent;
    background: var(--white-90);
    color: var(--solid-grey);
  }
  .inspiration-keyword.included {
    color: var(--white);
    background-color: var(--solid-blue);
  }
  .inspiration-keyword.excluded {
    color: var(--white);
    background-color: var(--bright-red);
    text-decoration: line-through;
  }
  .inspiration-keyword:hover {
    cursor: pointer;
    background-color: var(--white);
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey);
  }
  .delete-keyword {
    background-image: url("/buttons/btn_delete_keyword.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
    cursor: pointer;
    padding-right: 10px;
    width: 32px;
    height: 32px;
    margin-left: -12px;
    align-self: center;
  }
  .delete-keyword:hover {
    background-image: url("/buttons/btn_delete_keyword_hover.svg");
  }
  .clear-keywords {
    background-image: url("/buttons/btn_clear_keywords.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .dropdown {
    z-index: 999;
    padding: 10px 0px;
    position: fixed;
    background-color: var(--white-grey);
    border: 1px solid var(--solid-grey);
    border-radius: 12px;
  }
  .dropdown-item {
    padding: 10px 40px;
    background-size: 36px;
    background-position: 8px 1px;
    background-repeat: no-repeat;
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.included {
    color: var(--solid-blue);
    background-image: url("/icons/icon_check_include.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.excluded {
    color: var(--bright-red);
    background-image: url("/icons/icon_check_exclude.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item:hover {
    background-color: var(--white);
  }
  @media (max-width: 480px) {
    .spacer {
      padding-bottom: 0px;
      width: 100%;
    }
    /*  .inspiration-keyword {
      margin-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
      white-space: nowrap; }*/
  }
</style>
